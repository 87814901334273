import {
  isObject,
  keyBy,
  isEmpty,
  map,
  filter,
  mergeWith,
  isArray,
  chain,
  concat,
  find,
  merge,
  defaultTo,
  pick,
  get,
  set,
  unionBy,
  isInteger,
  omitBy,
  isNil,
  findIndex,
  findLastKey,
  startsWith,
  slice,
  includes,
  join,
  last,
  split,
  isNumber,
  isBoolean,
  some
} from 'lodash';
import uniqBy from 'lodash/uniqBy';
const insertAt = (arr, index, item) => {
  // slice(arr, index + 1)
  return concat(slice(arr, 0, index), item, slice(arr, index + 1));
};
const removeFalsy = (o) =>
  omitBy(o, (v) => isNil(v) || (isEmpty(v) && !isNumber(v) && !isBoolean(v)));

/**
 *
 * @param {*} key - that will be merged on
 * @param {*} child - child to be merged into
 * @param {*} master - master to be merged from
 * @param {*} settings - the following settings:
 * @param {*} settings.useMasterValue: () => false,
 * @param {*} settings.filterMasterBy: () => false,
 * @param {*} settings.valueKeys: '',
 * @param {*} settings.alwaysOverwriteKeys: '',
 * @param {*} settings.appendType: 'append'
 * @param {*} settings.fillInEmptyValueFromParent: false
 * @returns
 */
const mergeArrayByKey = (key, child, master, _settings = {}) => {
  if (!master) return child;
  const settings = {
    useMasterValue: () => false,
    filterMasterBy: () => false,
    valueKeys: '',
    alwaysOverwriteKeys: '',
    appendType: 'append',
    fillInEmptyValueFromParent: false,
    ..._settings
  };

  const getValues = (item, masterItem) => {
    if (settings.fillInEmptyValueFromParent)
      return {
        ...masterItem,
        ...removeFalsy(item)
      };
    return item;
  };

  return chain(child)
    .map((item) => {
      const masterItem = defaultTo(find(master, { key: item[key] }), item);

      if (settings.useMasterValue(item[key], key)) {
        if (masterItem && settings.valueKeys)
          return {
            ...getValues(item, masterItem),
            ...pick(masterItem, defaultTo(settings.valueKeys, '').split(','))
          };
        return masterItem;
      }

      return {
        ...getValues(item, masterItem),
        ...pick(
          masterItem,
          defaultTo(settings.alwaysOverwriteKeys, '').split(',')
        )
      };
    })
    .thru((c) => {
      const filteredMaster = filter(master, (item) =>
        settings.filterMasterBy(item[key], key)
      );
      if (settings.appendType == 'prepend') {
        /**
         * Need to reverse here otherwise it removes the child entries
         * with the uniqBy.
         */
        return chain(filteredMaster)
          .concat(c)
          .reverse()
          .uniqBy('key')
          .reverse()
          .value();
      } else if (settings.appendType == 'append') {
        return chain(c).concat(filteredMaster).uniqBy('key').value();
      } else if (settings.appendType == 'placeholder') {
        const index = findIndex(filteredMaster, {
          contact_type: 'placeholder'
        });
        const masterKeys = map(filteredMaster, 'key');

        const masterValues = map(
          filteredMaster,
          (item) => find(c, { key: item.key }) || item
        );
        return chain(masterValues)
          .thru((arr) =>
            insertAt(
              arr,
              index,
              filter(c, (item) => !masterKeys.includes(item.key))
            )
          )
          .flatten()
          .uniqBy('key')
          .value();
      }
      return uniqBy(c, 'key');
    })
    .value();
};
const getEntryByKey = (
  profile,
  path,
  key,
  valueKey,
  defaultPlaceholder = ''
) => {
  return (
    chain(profile)
      .get(path, [{ key, [valueKey]: defaultPlaceholder }])
      .find({ key })
      .value()?.[valueKey] || defaultPlaceholder
  );
};

const getPermissionWithKey = (permissions, itemId) =>
  defaultTo(
    find(permissions || [], (e) => e.key == itemId),
    {
      edit: true
    }
  );
const getRecordKeyFrommColorClass = (key) => {
  const type = 'links.list';
  const itemKey = last(split(key, '-'));
  const colorType = includes(key, 'background-color')
    ? 'background-color'
    : 'font-color';
  return join([type, itemKey, colorType], '.');
};

const reorderArrayByKey = (arr1, arr2, key) =>
  chain(arr1)
    .sortBy((item) => findIndex(arr2, { [key]: item[key] }))
    .value();
const hasCreatedOrEditedRecord = (recordKeys, records, key) =>
  some(
    map(
      recordKeys,
      (recordKey) =>
        records[recordKey.replace('{key}', key)]?.add ||
        records[recordKey.replace('{key}', key)]?.update
    )
  );

/**
 * We want everything that is in the template, that has been created by the child,
 * or that has been edited by the child to persist.
 */
const removeNonInterceptedEntries = (
  child,
  master,
  records = {},
  recordKeys = []
) => {
  return isEmpty(master)
    ? child
    : filter(child, (item) => {
        console.log(
          map(recordKeys, (recordKey) => recordKey.replace('{key}', item.key))
        );

        return (
          hasCreatedOrEditedRecord(recordKeys, records, item.key) ||
          find(master, { key: item.key })
        );
      });
};

const appendMode = (child, master = []) => {
  if (
    find(master, { contact_type: 'placeholder' }) ||
    find(master, { contactType: 'placeholder' })
  )
    return 'placeholder';
  if (isEmpty(child)) return 'prepend';
  return 'prepend';
};

const getLinks = (list) =>
  filter(
    list,
    ({ contact_type, key }) =>
      (contact_type == 'url' && key != 'navigate-to-office') ||
      contact_type == 'modal' ||
      contact_type == 'placeholder' ||
      contact_type == 'add-to-contacts'
  );
const getSocialMedia = (list) =>
  filter(list, ({ contact_type }) => contact_type == 'socialmedia');
const getContacts = (list) =>
  filter(
    list,
    ({ contact_type, key }) =>
      (contact_type != 'url' || key == 'navigate-to-office') &&
      contact_type != 'socialmedia' &&
      contact_type != 'modal' &&
      contact_type != 'add-to-contacts'
  );

const galleryMerged = (
  groupKeys,
  childGallery,
  masterGallery,
  valueKeys,
  useMasterValue = () => true,
  filterMasterBy = () => true,
  records = {}
) => {
  return chain(groupKeys)
    .map((groupKey) => {
      const partitionedGallery = filter(childGallery, { type: groupKey });
      const masterPartitioned = filter(masterGallery, { type: groupKey });
      if (isEmpty(partitionedGallery) && isEmpty(masterPartitioned)) return [];
      return mergeArrayByKey(
        'key',
        removeNonInterceptedEntries(
          partitionedGallery,
          masterPartitioned,
          records,
          [
            groupKey + '.list.{key}',
            groupKey + '.list.{key}.icon',
            groupKey + '.list.{key}.image',
            groupKey + '.list.{key}.date',
            groupKey + '.list.{key}.url',
            groupKey + '.list.{key}.title'
          ]
        ),
        masterPartitioned,
        {
          useMasterValue: (key) => useMasterValue(groupKey + '.list.' + key),
          filterMasterBy: (key) => filterMasterBy(groupKey + '.list.' + key),
          valueKeys,
          alwaysOverwriteKeys: 'enabled',
          appendType: appendMode(childGallery),
          fillInEmptyValueFromParent: true
        }
      );
    })
    .flatten()
    .unionBy('key')
    .value();
};

const deepMerge = (
  inObject /*Child*/,
  source /*Master*/,
  cur = {},
  key = ''
) => {
  if (!inObject) return source;
  const curVal = key ? get(inObject, key) : inObject;
  if (typeof curVal == 'object' && isInteger(curVal?.length)) {
    let index = 0;
    unionBy(curVal, get(source, key), () =>
      deepMerge(inObject, source, cur, key + `[${index++}]`)
    );
  } else if (curVal && typeof curVal == 'object') {
    for (const curKey in curVal) {
      const keyPath = !key
        ? curKey
        : key + (Array.isArray(curVal) ? `[${curKey}]` : '.' + curKey);
      deepMerge(inObject, source, cur, keyPath);
    }
  } else if (key) {
    if (!curVal) set(cur, key, get(source, key));
    else set(cur, key, curVal);
  }
  return cur;
};

const getData = (profile, path, defaultVal = '') =>
  isNil(get(profile, path)) ? defaultVal : get(profile, path);

const checkEmpty = (profile, path, defaultVal) =>
  isEmpty(get(profile, path)) || isNil(get(profile, path))
    ? defaultVal
    : get(profile, path);

const getPermissions = (profile) =>
  chain(checkEmpty(profile, 'masterProfile.master_permissions.permissions', []))
    .keyBy('key')
    .mergeWith(
      keyBy(
        checkEmpty(profile, 'masterProfile.child_permissions.permissions', []),
        'key'
      )
    )
    .mergeWith(
      keyBy(
        checkEmpty(profile, 'masterProfile.master_permission.permissions', []),
        'key'
      )
    )
    .mergeWith(
      keyBy(
        checkEmpty(profile, 'masterProfile.child_permission.permissions', []),
        'key'
      )
    )
    .mergeWith(
      keyBy(checkEmpty(profile, 'master_permissions.permissions', []), 'key')
    )
    .mergeWith(
      keyBy(checkEmpty(profile, 'master_permission.permissions', []), 'key')
    )
    .values()
    .value();

const recordWasRemoved = (key, records) =>
  chain(records).get(key).defaultTo({ remove: 0 }).get('remove').value();

const recordWasEdited = (key, records) => {
  return chain(records)
    .get(findLastKey(records, (o, k) => k.includes(key)))
    .defaultTo({ remove: 0 })
    .get('update')
    .value();
};
const permissionsDictionary = {
  qrCodeData: 'QRCode.content',
  iconStyle: 'appearance.iconStyle',
  profileDisclaimer: 'profileDisclaimer.value'
};

const mergeProfiles = (profile, master, permissions, records) => {
  if (!master || (isObject(master) && isEmpty(master))) return profile;

  const canEdit = (key) => getPermissionWithKey(permissions, key).edit;
  const useMasterValue = (permissionKey, recordKey) =>
    !canEdit(permissionKey) &&
    !recordWasEdited(recordKey || permissionKey, records);
  const filterMasterBy = (key) =>
    !canEdit(key) || !recordWasRemoved(key, records);
  const getRecord = (key, type) =>
    chain(records).get(key).defaultTo({ remove: 0 }).get(type).value();

  // Merge Additional information sans modals

  const profileNonModalSections = filter(
    profile.additional_information,
    ({ isModal }) => !isModal
  );
  const masterNonModalSections = filter(
    master.additional_information,
    ({ isModal }) => !isModal
  );
  console.log(
    removeNonInterceptedEntries(
      profileNonModalSections,
      masterNonModalSections,
      records,
      ['{key}']
    )
  );

  const infoSectionsUnordered = mergeArrayByKey(
    'key',
    // removeNonMatches
    removeNonInterceptedEntries(
      profileNonModalSections,
      masterNonModalSections,
      records,
      ['{key}', '{key}.content']
    ),
    masterNonModalSections,
    {
      useMasterValue: (key) => useMasterValue(key + '.content', key),
      filterMasterBy: (key) => filterMasterBy(key + '.content'),
      valueKeys: 'title,icon,description',
      appendType: appendMode(profileNonModalSections),
      fillInEmptyValueFromParent: true
    }
  );

  // Merge buttons/links
  const links = mergeArrayByKey(
    'key',
    // If links are not editable, then you don't want user added entries.
    removeNonInterceptedEntries(
      getLinks(profile.contacts),
      getLinks(master.contacts),
      records,
      [
        'links.list.{key}',
        'links.list.{key}.url',
        'links.list.{key}.title',
        'links.list.{key}.icon'
      ]
    ),
    getLinks(master.contacts),
    {
      useMasterValue: (key) => useMasterValue('links.list.' + key),
      filterMasterBy: (key) => filterMasterBy('links.list.' + key),
      valueKeys: 'value,icon,name',
      appendType: appendMode(
        getLinks(profile.contacts),
        getLinks(master.contacts)
      ),
      fillInEmptyValueFromParent: true
    }
  );

  // Css overwrite
  const profileCssVariables = chain(profile.cssVariables)
    .map((v, key) => ({ key, ...v }))
    .filter(({ key }) => startsWith(key, 'contact-button'))
    .value();
  const masterCssVariables = chain(master.cssVariables)
    .map((v, key) => ({ key, ...v }))
    .filter(({ key }) => startsWith(key, 'contact-button'))
    .value();

  const cssVariables = keyBy(
    mergeArrayByKey(
      'key',
      // removeNonMatches
      removeNonInterceptedEntries(
        profileCssVariables,
        masterCssVariables,
        records,
        ['{key}']
      ),
      masterCssVariables,
      {
        useMasterValue: (key) =>
          useMasterValue(getRecordKeyFrommColorClass(key)),
        filterMasterBy: (key) =>
          filterMasterBy(getRecordKeyFrommColorClass(key)),
        appendType: 'append',
        fillInEmptyValueFromParent: true
      }
    ),
    'key'
  );

  // Merge modals

  const profileModalSections = filter(profile.additional_information, {
    isModal: true
  });
  const masterModalSections = filter(master.additional_information, {
    isModal: true
  });

  const getKeyFromLinks = (key) => {
    const temp = chain(links).find({ value: key }).get('key', '').value();
    return temp;
  };

  const infoModalsUnordered = mergeArrayByKey(
    'key',
    // removeNonMatches
    removeNonInterceptedEntries(
      profileModalSections,
      masterModalSections,
      records,
      ['{key}']
    ),
    masterModalSections,
    {
      useMasterValue: (key) =>
        useMasterValue(
          'links.list.' + getKeyFromLinks(key),
          'modals.list.' + key + '.description'
        ),
      filterMasterBy: (key) =>
        filterMasterBy(
          'links.list.' + getKeyFromLinks(key),
          'modals.list.' + key + '.description'
        ),
      valueKeys: 'title,icon,description',
      appendType: appendMode(profileModalSections),
      fillInEmptyValueFromParent: true
    }
  );

  // If infoSections is uneditable, then it should always take the parents order
  const infoSections = concat(
    canEdit('infoSections')
      ? infoSectionsUnordered
      : reorderArrayByKey(
          infoSectionsUnordered,
          master.additional_information,
          'key'
        ),
    infoModalsUnordered
  );

  const galleryImages = galleryMerged(
    map(infoSections, 'key'),
    profile.image_gallery,
    master.image_gallery,
    'url,link,expireAt',
    useMasterValue,
    filterMasterBy,
    records
  );

  const galleryVideos = galleryMerged(
    map(infoSections, 'key'),
    profile.video_gallery,
    master.video_gallery,
    'embed',
    useMasterValue,
    filterMasterBy,
    records
  );

  const mergedProfile = mergeWith(
    JSON.parse(JSON.stringify(master)),
    profile,
    (a, b, key) => {
      // b === child; a === master
      if (isArray(b)) {
        return concat(b || [], a || []);
      }
      // B must be false, can't be null
      if (key == 'about_me_video_enabled') {
        if (b == false) return false;
        if (b == true) return true;
        return a;
      }
      // B must be empty
      if (key == 'qrCodeData' || key == 'iconStyle' || key == 'page_footer') {
        if (b && !isEmpty(b) && canEdit(permissionsDictionary[key])) return b;
        return a;
      }
      // Deep merge object
      if (
        key == 'person' ||
        key == 'logo' ||
        key == 'banner' ||
        key == 'biography'
      ) {
        return deepMerge(b, a);
      }
      // Shallow merge object
      if (key == 'call_to_action' || key == 'cssVariables') {
        return merge(removeFalsy(a), removeFalsy(b));
      }

      return !b ? a : b;
    }
  );
  mergedProfile.cssVariables = {
    ...mergedProfile.cssVariables,
    ...cssVariables
  };

  //Contacts
  const contactPermissionKeys = {
    mobile: 'personalDetails.mainContactNumber',
    whatsapp: 'personalDetails.whatsappNumber',
    office: 'personalDetails.officeNumber',
    email: 'personalDetails.emailAddress',
    'navigate-to-office': 'personalDetails.navigateToOffice'
  };

  const fillURL = (link) => {
    if (link?.value && canEdit(contactPermissionKeys[link.key])) return link;
    return {
      ...link,
      value: getEntryByKey(master, 'contacts', link.key, 'value', '')
    };
  };

  const contacts = chain(getContacts(profile.contacts))
    .map(fillURL)
    .concat(filter(getContacts(master.contacts), { key: 'navigate-to-office' }))
    .unionBy('key')
    .value();

  mergedProfile.image_gallery = galleryImages;
  mergedProfile.video_gallery = galleryVideos;
  mergedProfile.additional_information = infoSections;
  mergedProfile.contacts = concat(
    contacts,
    links,
    chain(getSocialMedia(profile.contacts))
      .concat(getSocialMedia(master.contacts))
      .uniqBy('key')
      .filter((item) => {
        const key = 'socialMedia.list.' + item.key;
        return (
          !recordWasRemoved('socialMedia.list.' + item.key, records) ||
          getRecord(key, 'add') > getRecord(key, 'remove')
        );
      })
      .value()
  );
  return mergedProfile;
};

export {
  permissionsDictionary,
  reorderArrayByKey,
  mergeProfiles,
  getPermissions,
  mergeArrayByKey,
  getEntryByKey,
  getPermissionWithKey,
  removeNonInterceptedEntries,
  appendMode,
  galleryMerged,
  getData,
  checkEmpty,
  recordWasRemoved,
  recordWasEdited
};
